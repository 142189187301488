div.Footer-Component {
  text-align: center;
  margin-top: 40px;
}

.Footer-Component .Copyright {
  text-align: center;
  font-size: 0.9em;
}

.Footer-Component .Links {
  font-size: 0.9em;
  margin: 20px 0;
}

.Footer-Component .Links a {
  color: #ECEFF1;
}

.Footer-Component .Links a:hover,
.Footer-Component .Links a.active
{
  text-decoration: none;
}
