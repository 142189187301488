div.Header-Component {
  text-align: center;
  padding: 20px 10px 10px;
  margin: 0;
}

.Header-Component .Logo {
  text-align: center;
  font-size: 35px;
  font-weight: bold;
}

.Header-Component .Logo img {
  height: 50px;
  vertical-align: middle;
  margin-right: 2vw;
}

.Header-Component .Links {
  font-size: 0.9em;
  margin: 20px 0;
}

.Header-Component .Links a {
  color: #ECEFF1;
}

.Header-Component .Links a:hover {
  text-decoration: none;
}
