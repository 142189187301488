@import url('https://fonts.googleapis.com/css2?family=Schoolbell&display=swap');

html {
  background-color: #282c34;
  display: block;
}

html, body {
  color: #ECEFF1;
  font-size: 16px;
}

body {
  padding: 0;
  margin: 0;
}

body {
  margin: 0;
  font-family: 'Schoolbell', cursive;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: #ffa100;
}

a:hover {
  color: #ffa100;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Main {
  max-width: 900px;
  padding: 0 10px;
  alignment: center;
  margin: auto auto;
}

.Main-Content {
  padding: 0 10px;
}
