@import url(https://fonts.googleapis.com/css2?family=Schoolbell&display=swap);
html {
  background-color: #282c34;
  display: block;
}

html, body {
  color: #ECEFF1;
  font-size: 16px;
}

body {
  padding: 0;
  margin: 0;
}

body {
  margin: 0;
  font-family: 'Schoolbell', cursive;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: #ffa100;
}

a:hover {
  color: #ffa100;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Main {
  max-width: 900px;
  padding: 0 10px;
  alignment: center;
  margin: auto auto;
}

.Main-Content {
  padding: 0 10px;
}

.App {
  text-align: center;
}

.App-logo {
  height: 30vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
  display: flex;
  flex-direction: column;
  align-content: center;
  vertical-align: middle;
}

.Container {
  background-color: #282c34;
  min-height: 70vh;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.Inner-Container {
  background-color: #282c34;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.Left-panel {
}

.Right-panel {
  margin-left: 7vw;
  text-align: left;
  font-size: calc(10px + 2vmin);
}

.Contact {
  font-size: calc(10px + 0.7vmin);
}

.Break {
  flex-basis: 100%;
  height: 0;
}

/*
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
*/

div.Footer-Component {
  text-align: center;
  margin-top: 40px;
}

.Footer-Component .Copyright {
  text-align: center;
  font-size: 0.9em;
}

.Footer-Component .Links {
  font-size: 0.9em;
  margin: 20px 0;
}

.Footer-Component .Links a {
  color: #ECEFF1;
}

.Footer-Component .Links a:hover,
.Footer-Component .Links a.active
{
  text-decoration: none;
}

div.Header-Component {
  text-align: center;
  padding: 20px 10px 10px;
  margin: 0;
}

.Header-Component .Logo {
  text-align: center;
  font-size: 35px;
  font-weight: bold;
}

.Header-Component .Logo img {
  height: 50px;
  vertical-align: middle;
  margin-right: 2vw;
}

.Header-Component .Links {
  font-size: 0.9em;
  margin: 20px 0;
}

.Header-Component .Links a {
  color: #ECEFF1;
}

.Header-Component .Links a:hover {
  text-decoration: none;
}

